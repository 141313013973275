import React, { useState, useEffect } from "react"
import "./Wash_Complete.css"
import wash from "../../../images/wash.svg"
import wash_complete from "../../../images/wash_complete.svg"
import { Link } from "gatsby"
import Button from "../../../components/Yard/Button/Button"
import { Routes } from "../../../utils/route"
import Header from "../../../components/Yard/header/Header"
import { Spin } from "antd"
const Wash_Complete = props => {
  const id = props.id
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
      }
    }
    Routes("yard", callback)
  }, [])
  if (!loading) {
    return (
      <>
        <Header LinkName={`/yard/home`} fromComplete={true} />
        <section className="wash_complete">
          <div className="wash_img">
            <img src={wash} alt="wash img" />
          </div>
          <div className="wash_comp_container">
            <img src={wash_complete} alt="wash img" />
            <p>
              Click below to confirm that wash is complete. This will close out
              the wash ticket and you will be able to add any notes or photos of
              post wash.
            </p>
            <div className="row">
              <div className="wash_complete_button col">
                <Link to={`/yard/home`}>
                  <Button className="wash_button-2" name="Home" />
                </Link>
              </div>
              <div className="wash_complete_button col">
                <Link
                  to={`/yard/wash_details/${id}`}
                  state={{
                    item: props?.location?.state?.item,
                    services: props?.location?.state?.services,
                  }}
                >
                  <Button className="wash_button" name="mark wash complete" />
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}
export default Wash_Complete
